<script setup>
import logoutSmall from "assets/graphics/icons/cb_logout_small.svg";
import logoCareDayWhite from "assets/graphics/icons/logo_careboard_weiss.png";
import NavBarLink from "~/components/NavBarLink.vue";
import { signOutUser } from "~/composables/useFirebase";

const handleLogout = () => {
  signOutUser();
};
</script>

<template>
  <nav class="navbar navbar-expand-md navbar-dark cb_color fixed-left">
    <a
      class="navbar-brand"
      href="/"
    ><img :src="logoCareDayWhite" style="width: 95%"></a>
    <br>

    <h3 style="color: red;">
      {{ $t("admin.admin") }}
    </h3>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>

    <div
      id="navbarsExampleDefault"
      class="collapse navbar-collapse cb_color"
    >
      <ul class="navbar-nav cb_nav">
        <NavBarLink
          :text="$t('admin.overview')"
          icon="homeSmall"
          to="/admin"
        />

        <NavBarLink
          text="CareDays"
          icon="settingsSmall"
          to="/admin/caredays"
        />
        <NavBarLink
          :text="$t('admin.new')"
          icon="plus"
          to="/admin/caredays/new"
          indent
        />
        <NavBarLink
          :text="$t('admin.facilities')"
          icon="cb_residence_small.svg"
          to="/admin/facilities"
        />
        <NavBarLink
          :text="$t('admin.new')"
          icon="plus"
          to="/admin/facilities/new"
          indent
        />
        <NavBarLink
          :text="$t('admin.organizations')"
          icon="settingsSmall"
          to="/admin/organizations"
        />
        <NavBarLink
          :text="$t('admin.new')"
          icon="plus"
          to="/admin/organizations/new"
          indent
        />
        <NavBarLink
          :text="$t('admin.users')"
          icon="user"
          to="/admin/users"
        />
        <NavBarLink
          :text="$t('admin.new')"
          icon="plus"
          to="/admin/users/new"
          indent
        />

        <li>
          <a
            class="cb_nav-link"
            @click="handleLogout"
          >
            <img :src="logoutSmall" :alt="$t('navigation.log_out')"> {{ $t("navigation.log_out") }}</a>
        </li>
      </ul>
      <br>
    </div>
  </nav>
</template>

<style scoped>
.navbar.fixed-left {
  z-index: 1030;
  padding: 1rem;
}

@media (min-width: 768px) {
  .navbar.fixed-left {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 232px;
    flex-flow: column nowrap;
    align-items: flex-start
  }

  .navbar.fixed-left .navbar-collapse {
    flex-grow: 0;
    flex-direction: column;
    width: 100%
  }

  .navbar.fixed-left .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%
  }

  .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item {
    width: 100%
  }

  .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
    top: 0
  }
}

@media (min-width: 768px) {
  .navbar.fixed-left {
    right: auto
  }

  .navbar.fixed-left .navbar-nav .nav-item .dropdown-toggle:after {
    border-top: .3em solid transparent;
    border-left: .3em solid;
    border-bottom: .3em solid transparent;
    border-right: none;
    vertical-align: baseline
  }

  .navbar.fixed-left .navbar-nav .nav-item .dropdown-menu {
    left: 100%
  }
}

.cb_nav {
  font-size: 0.9rem;
  color: #F5F7FC;
  text-decoration: none !important;
}

.cb_nav li {
  padding: 0.3rem;
}

.cb_nav img {
  padding-right: 0.5rem;
}

.cb_nav a {
}

.cb_color {
  background-color: #073946;
}

.cb_nav-img img {
  width: 1rem;
  margin-right: 0.5rem;
}

.cb_nav-link {
  color: #F5F7FC;
  text-decoration: none !important;
}

.cb_nav-link:hover {
  color: #F2D028;
  text-decoration: none;
}
</style>
